export const Footer = () => import('../../components/Footer/index.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/NavBar/index.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const LoAndReCaptacha = () => import('../../components/LoAndRe/captacha.vue' /* webpackChunkName: "components/lo-and-re-captacha" */).then(c => wrapFunctional(c.default || c))
export const LoAndReForget = () => import('../../components/LoAndRe/forget.vue' /* webpackChunkName: "components/lo-and-re-forget" */).then(c => wrapFunctional(c.default || c))
export const LoAndReLogin = () => import('../../components/LoAndRe/login.vue' /* webpackChunkName: "components/lo-and-re-login" */).then(c => wrapFunctional(c.default || c))
export const LoAndReRegister = () => import('../../components/LoAndRe/register.vue' /* webpackChunkName: "components/lo-and-re-register" */).then(c => wrapFunctional(c.default || c))
export const Gencode = () => import('../../components/gencode/index.vue' /* webpackChunkName: "components/gencode" */).then(c => wrapFunctional(c.default || c))
export const PrintDialog = () => import('../../components/printDialog/index.vue' /* webpackChunkName: "components/print-dialog" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
